.hd-layout__wrapper {
    box-sizing: border-box;
    width: min(100% - 2rem, var(--layout-width));
    margin-inline: auto;
}

.hd-layout__fluid {
    padding-inline: var(--hd-space-2);
}

.hd-layout__with-sidebar {
    @media screen and (width >= 48rem) {
        display: grid;
        grid-template-columns: var(--nav-width) minmax(0, 1fr);
        gap: var(--hd-space-6);
        padding-inline: var(--hd-space-2);
    }
}
